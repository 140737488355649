<div class="loading" *ngIf="loading">
    <img src="../../../../assets/img/bluebg.png">
</div>

<div>
    <div class="bg"></div>
    <div class="row bg-primary minh-80">
        <div class="separator-h"></div>
        <div class="separator-h"></div>
        <div class="separator-h"></div>
        <div class="col mb-auto ml-auto mr-auto mobile-width mobile-center">
            <div class="p-2 ml-auto mr-auto">
                <h1 class="size-1 text-white light zi-2">La <span class="ofont ofont-small">belleza</span> de la obra<br>está <span class="ofont ofont-small">en el gusto</span>.</h1>
            </div>
        </div>
            <img src="../../../../assets/img/bluebg.png" class="absolute zi-2 mt-300 w-50 anim1 mobile-hide">
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#9ae7ff" fill-opacity="1" d="M0,192L48,213.3C96,235,192,277,288,272C384,267,480,213,576,186.7C672,160,768,160,864,133.3C960,107,1056,53,1152,48C1248,43,1344,85,1392,106.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
    </svg>
</div>
<div class="row">
    <div class="col text-center mt-auto mb-auto mobile-width mobile-center">
        <img src="../../../../assets/img/bluetwo.png" class="anim2">
    </div>
    <div class="col text-right mt-auto mb-auto mobile-width mobile-center">
        <h1>Realizo obras de personajes reales y ficticios,<br> en <span class="ofont ofont-small">diversos estilos</span></h1>
        <a routerLink="/estilos"><button class="btn btn-outline-black">Ver todos los estilos</button></a>
    </div>
</div>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#922c94" fill-opacity="1" d="M0,32L48,69.3C96,107,192,181,288,192C384,203,480,149,576,144C672,139,768,181,864,202.7C960,224,1056,224,1152,213.3C1248,203,1344,181,1392,170.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
</svg>
<div class="row bg-secondary">
    <div class="col text-center mt-auto mb-auto">
        <h1 class="text-white light">¿Aún no has visto mis <span class="ofont ofont-small">obras</span>?</h1>
        <a routerLink="/galeria"><button class="btn btn-outline-white">Ver todas las obras</button></a>
    </div>
    <div class="col text-center mt-auto mb-auto">
        <img src="../../../../assets/img/bluethree.png" class="absolute zi-2 mt--900 w-50 anim1 mobile-hide">
    </div>
</div>
<svg style="background: #494949;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#922c94" fill-opacity="1" d="M0,32L48,69.3C96,107,192,181,288,192C384,203,480,149,576,144C672,139,768,181,864,202.7C960,224,1056,224,1152,213.3C1248,203,1344,181,1392,170.7L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
</svg>