import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EndpointService } from 'src/app/services/endpoint.service';
import marked, { Renderer } from 'marked';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  md:any;
  slug;
  page;

  constructor(private router: Router,private api: EndpointService) {

    let _id: string = '';
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        _id = val.url.replace("/",'');
          this.slug = _id;
      }
    });

    this.md = marked.setOptions({ Renderer });

  }

  async ngOnInit() {

    this.api.getData(this.api._url+"/paginas").subscribe(data=>{

      let d;

      d = data;

      let datafind = false;

      for (let i = 0; i < d.length; i++) {
        if (d[i].slug == this.slug) {
          datafind = true;
          this.page = d[i];
        }
      }

      if (!datafind) {
        this.router.navigate(['404']);
      }

    });

  }

  transformAsHtml(value:string) {
    if (value) {
      value = value.split('/uploads').join(this.api._url+"/uploads");
      value = this.md(value);
      value = value.split('href').join("target='_blank' href");
      value = value.split('img').join("img class='max-width'");
      return value;
    }
  }

}
