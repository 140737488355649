<div class="minh-100">
    <div class="row bg-primary">
        <div class="separator-h"></div>
        <div class="separator-h"></div>
        <div class="col text-center">
            <h1 class="text-white light">Mis estilos</h1>
        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#9ae7ff" fill-opacity="1" d="M0,256L1440,64L1440,0L0,0Z"></path>
    </svg>
    <div class="row w-100 relative bgstyle-br-right">
        <div *ngIf="cfg && cfg.simpsons_fondo.url" class="bgstyle bgstyle-br-right" style="background-image: url({{url+cfg.simpsons_fondo.url}});"></div>
        <div class="bgstyle-black bgstyle-br-right"></div>
        <div class="col mt-auto mb-auto relative mobile-width mobile-center">
            <h1 class="ofont size-1 text-white text-center">Simpsons</h1>
        </div>
        <div class="col mt-auto mb-auto relative text-center mobile-width mobile-center">
            <img *ngIf="cfg && cfg.simpsons.url" class="style_avt style_avt-right" src="{{url+cfg.simpsons.url}}">
        </div>
    </div>
    <div class="separator-h"></div>
    <div class="separator-h"></div>
    <div class="separator-h"></div>
    <div class="row w-100 relative bgstyle-br-left">
        <div *ngIf="cfg && cfg.sinlineart_fondo.url" class="bgstyle bgstyle-br-left" style="background-image: url({{url+cfg.sinlineart_fondo.url}});"></div>
        <div class="bgstyle-black bgstyle-br-left"></div>
        <div class="col mt-auto mb-auto relative mobile-width mobile-center">
            <img *ngIf="cfg && cfg.sinlineart.url" class="style_avt style_avt-right" src="{{url+cfg.sinlineart.url}}">
        </div>
        <div class="col mt-auto mb-auto relative text-center mobile-width mobile-center">
            <h1 class="ofont size-1 text-white text-center">Sin lineart</h1>
        </div>
    </div>
    <div class="separator-h"></div>
    <div class="separator-h"></div>
    <div class="separator-h"></div>
    <div class="row w-100 relative bgstyle-br-right">
        <div *ngIf="cfg && cfg.anime_fondo.url" class="bgstyle bgstyle-br-right" style="background-image: url({{url+cfg.anime_fondo.url}});"></div>
        <div class="bgstyle-black bgstyle-br-right"></div>
        <div class="col mt-auto mb-auto relative mobile-width mobile-center">
            <h1 class="ofont size-1 text-white text-center">Ánime</h1>
        </div>
        <div class="col mt-auto mb-auto relative text-center mobile-width mobile-center">
            <img *ngIf="cfg && cfg.anime.url" class="style_avt style_avt-right" src="{{url+cfg.anime.url}}">
        </div>
    </div>
    <div class="separator-h"></div>
    <div class="separator-h"></div>
    <div class="separator-h"></div>
    <div class="row w-100 relative bgstyle-br-left">
        <div *ngIf="cfg && cfg.chibi_fondo.url" class="bgstyle bgstyle-br-left" style="background-image: url({{url+cfg.chibi_fondo.url}});"></div>
        <div class="bgstyle-black bgstyle-br-left"></div>
        <div class="col mt-auto mb-auto relative mobile-width mobile-center">
            <img *ngIf="cfg && cfg.chibi.url" class="style_avt style_avt-right" src="{{url+cfg.chibi.url}}">
        </div>
        <div class="col mt-auto mb-auto relative text-center mobile-width mobile-center">
            <h1 class="ofont size-1 text-white text-center">Chibi</h1>
        </div>
    </div>
    <div class="separator-h"></div>
    <div class="separator-h"></div>
    <div class="separator-h"></div>
    <div class="row w-100 relative bgstyle-br-right">
        <div *ngIf="cfg && cfg.desnudo_fondo.url" class="bgstyle bgstyle-br-right" style="background-image: url({{url+cfg.desnudo_fondo.url}});"></div>
        <div class="bgstyle-black bgstyle-br-right"></div>
        <div class="col mt-auto mb-auto relative mobile-width mobile-center">
            <h1 class="ofont size-1 text-white text-center">Desnudo</h1>
        </div>
        <div class="col mt-auto mb-auto relative text-center mobile-width mobile-center">
            <img *ngIf="cfg && cfg.desnudo.url" class="style_avt style_avt-right" src="{{url+cfg.desnudo.url}}">
        </div>
    </div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#494949" fill-opacity="1" d="M0,256L1440,64L1440,320L0,320Z"></path>
</svg>