import { Component, OnInit } from '@angular/core';
import { EndpointService } from 'src/app/services/endpoint.service';

@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.css'],
  providers: [EndpointService]
})
export class StylesComponent implements OnInit {

  cfg;
  url;

  constructor(private api: EndpointService) {

    this.url = this.api._url;

    this.api.getData(this.api._url+"/configuracion").subscribe(data=>{
      this.cfg = data;
    });

  }

  ngOnInit(): void {
    window.scrollTo(0,0);
  }

}
