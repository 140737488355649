<div class="row bg-footer">
    <div class="col mt-auto mb-auto text-left text-white mobile-center">
        <img src="../../../assets/img/bluelogo.png" class="logo shrink">
        <p>Me dedico a la creación de obras artísticas<br>en diversos estilos y formas.</p>
        <a href="https://www.instagram.com/blueart.me/" target="_blank"><button class="btn btn-outline-white"><i class="fab fa-instagram"></i> ¡Sígueme en instagram!</button></a>
    </div>
    <div class="col mt-auto mb-auto text-right text-white mobile-center">
        <h1 class="light">Información</h1>
        <p><a href="/politica-privacidad">Política de privacidad</a></p>
        <p><a href="/terminos-del-servicio">Términos del servicio</a></p>
        <p><a routerLink="/solicitar-presupuesto">Solicitar un presupuesto</a></p>
        <p><a href="mailto:bluemoonart711@gmail.com">Contacto</a></p>
    </div>
</div>
<div class="row bg-black">
    <div class="col text-white text-center">
        Blueart &copy; {{date}}
    </div>
</div>