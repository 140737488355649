import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LandingComponent } from './components/pages/landing/landing.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { BudgetComponent } from './components/pages/budget/budget.component';
import { PageComponent } from './components/pages/page/page.component';
import { Error404Component } from './components/error404/error404.component';
import { StylesComponent } from './components/pages/styles/styles.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    GalleryComponent,
    BudgetComponent,
    PageComponent,
    Error404Component,
    StylesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
