import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  constructor(private http: HttpClient) { }

  _url = "https://strapi.blueart.me";

  getData(url:string) {
    return this.http.get(url);
  }

  postData(url:string,object:Object) {
    return this.http.post(url,object);
  }

}
