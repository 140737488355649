<div class="mobilemenu zi-999 {{showMenu?'mobilemenu-active':'mobilemenu-hide'}}">
    <div class="mmenu_bg" (click)="showMenu=false"></div>
    <div class="area text-center">
        <div class="row">
            <div class="col">
                <img src="../../../assets/img/bluelogo.png" class="logo text-center shrink">
            </div>
            <div class="col mt-auto mb-auto">
                <h1 (click)="showMenu=false" class="text-white relative"><i class="fas fa-times"></i></h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div *ngFor="let m of menu">
                    <h1 (click)="showMenu=false"><a class="text-white" routerLink="/{{m.slug}}">{{m.titulo}}</a></h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row header w-100 relative zi-999">
    <div class="col mt-auto mb-auto mobile-center">
        <img src="../../../assets/img/bluelogo.png" class="logo text-center shrink">
    </div>
    <div class="col mt-auto mb-auto text-right">
        <div *ngIf="menu" class="menu">
            <div class="mobile-hide">
                <div *ngFor="let m of menu" class="ib relative">
                    <h3 class="light"><a class="text-white" routerLink="/{{m.slug}}">{{m.titulo}}</a></h3>
                </div>
            </div>
            <h1 (click)="showMenu=true"><i class="fas fa-bars text-white mobile-show baranim text-center zi-999 relative"></i></h1>
        </div>
    </div>
</div>