<div class="minh-100">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#9ae7ff" fill-opacity="1" d="M0,256L1440,64L1440,0L0,0Z"></path>
    </svg>
    <div class="row w-100">
        <div class="col text-center">
            <p>Opps... error</p>
            <h1 class="size-1">404</h1>
            <p>¡Parece que la página que buscas, no existe!</p>
        </div>
    </div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#494949" fill-opacity="1" d="M0,256L1440,64L1440,320L0,320Z"></path>
</svg>