import { EndpointService } from './../../../services/endpoint.service';
import { Component, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  images;
  url;
  imageToShow;
  class = "chide";

  constructor(private api: EndpointService) {
    
  }

  async ngOnInit() {

    this.url = this.api._url;

    this.api.getData(this.api._url+"/galeria").subscribe(data=>{
      this.images = data['imagenes'];
      this.images = this.images.reverse();
    });

    window.scrollTo(0,0);

  }

  setImage(image:string) {

    this.imageToShow = this.url+image;

    setTimeout(() => {
      this.class="cshow";
    }, 500);
  }

  close() {
    this.class="chide";
    setTimeout(() => {
      this.imageToShow=false;
    }, 1000);
  }

  carousel_action(action:string) {

    let canChange = true;

    if (action=="left") {
      this.class="moveLeft";
    } else {
      this.class="moveRight";
    }

    setTimeout(() => {
      this.class="cshow";

        for (let i = 0; i < this.images.length; i++) {
          if (canChange) {
          
          if (this.url+this.images[i].url == this.imageToShow) {

            if (action=="left") {
              if (i>0) {
                this.imageToShow = this.url+this.images[i-1].url;
              } else {
                this.imageToShow = this.url+this.images[this.images.length-1].url;
              }
            } else {
              if (i==this.images.length-1) {
                this.imageToShow = this.url+this.images[0].url;
              } else {
                this.imageToShow = this.url+this.images[i+1].url;
              }
            }

            canChange = false;

            }

          }

      }


    }, 1000);

  }

}
