<div class="minh-100">
    <div class="row bg-primary">
        <div class="separator-h"></div>
        <div class="separator-h"></div>
        <div class="col text-center">
            <h1 class="text-white light">Solicitar presupuesto</h1>
        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#9ae7ff" fill-opacity="1" d="M0,256L1440,64L1440,0L0,0Z"></path>
    </svg>
    <div class="row w-100">
        <div class="col text-center">
            <div class="alert bg-warning">Aviso: Las obras pueden no coincidir al 100% con lo solicitado, trataré de realizar lo más parecido posible pero no garantizo que sea idéntico.</div>
            <img *ngIf="precios" src="{{precios}}" style="max-width:100%">
            <input placeholder="¿Cómo te llamas?" type="text" name="name" [(ngModel)]="form_name"><br>
            <input placeholder="¿Cuál es tu correo electrónico?" type="email" name="email" [(ngModel)]="form_email"><br>
            <select name="type" [(ngModel)]="form_tipo">
                <option *ngIf="form_tipo=='def'" value="def">¿Qué tipo de dibujo te gustaría?</option>
                <option value="simpsons">Simpsons</option>
                <option value="chibi">Chibi</option>
                <option value="anime">Ánime</option>
                <option value="sin-line-art">Sin lineart</option>
                <option value="desnudos">Desnudos</option>
                <option value="otro">Otro</option>
            </select><br>
            <input *ngIf="form_tipo=='otro'" placeholder="¿A qué tipo de estilo te refieres?" type="text" name="other" [(ngModel)]="form_tipo2"><br>
            <textarea name="observations" placeholder="Observaciones" [(ngModel)]="form_observaciones"></textarea><br><br><br>
            <input placeholder="¿Qué símbolo se encuentra en la firma y logotipo?" [(ngModel)]="form_checker">
            <div *ngIf="alert" class="alert bg-{{alertType}}">{{alert}}</div>
            <button (click)="submitData()" class="btn btn-outline-black w-100">Enviar presupuesto</button>
        </div>
    </div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#494949" fill-opacity="1" d="M0,256L1440,64L1440,320L0,320Z"></path>
</svg>