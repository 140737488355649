import { PageComponent } from './components/pages/page/page.component';
import { Error404Component } from './components/error404/error404.component';
import { BudgetComponent } from './components/pages/budget/budget.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { LandingComponent } from './components/pages/landing/landing.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StylesComponent } from './components/pages/styles/styles.component';


const routes: Routes = [

  {path: '', component: LandingComponent},
  {path: 'galeria', component: GalleryComponent},
  {path: 'solicitar-presupuesto', component: BudgetComponent},
  {path: 'estilos', component: StylesComponent},
  {path: '404', component: Error404Component},
  {path: ':id', component: PageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
