<div class="minh-100">
    <div class="row bg-primary">
        <div class="separator-h"></div>
        <div class="separator-h"></div>
        <div class="col text-center">
            <h1 *ngIf="page" class="text-white light">{{page.titulo}}</h1>
        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#9ae7ff" fill-opacity="1" d="M0,256L1440,64L1440,0L0,0Z"></path>
    </svg>
    <div class="row w-100">
        <div class="col">
            <div *ngIf="page" [innerHTML]="transformAsHtml(page.contenido)"></div>
        </div>
    </div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#494949" fill-opacity="1" d="M0,256L1440,64L1440,320L0,320Z"></path>
</svg>