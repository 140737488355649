import { EndpointService } from './../../../services/endpoint.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css'],
  providers: [EndpointService]
})
export class BudgetComponent implements OnInit {

  constructor(private api: EndpointService) { }

  form_name:string;
  form_email:string;
  form_tipo:string="def";
  form_tipo2:string;
  form_observaciones:string;
  alert:string;
  alertType:string;
  form_checker:string;

  precios;

  ngOnInit(): void {
    window.scrollTo(0,0);

    this.api.getData(this.api._url+"/configuracion").subscribe(res=>{
      this.precios = this.api._url+res['precios'].url;
    });
  }

  submitData() {

    this.alert="";
    this.alertType="";

    if (!this.form_name||!this.form_email||!this.form_tipo||!this.form_observaciones) {
      this.alertType = "danger";
      this.alert = "¡Faltan campos por rellenar!";
      return;
    }

    if (this.form_tipo=="def") {
      this.alertType = "warning";
      this.alert = "¡El valor de tipo no puede quedar sin rellenar!";
      return;
    }

    if (this.form_tipo=="otro"&&!this.form_tipo2) {
      this.alertType = "warning";
      this.alert = "¡El valor de tipo no puede quedar sin rellenar!";
      return;
    }


    if (this.form_checker!==undefined&&(this.form_checker.toUpperCase() == "CORAZÓN" || this.form_checker.toUpperCase() == "CORAZON")) {
      this.api.postData(this.api._url+"/presupuestos",{

        nombre:this.form_name,
        email:this.form_email,
        tipo:this.form_tipo2?this.form_tipo2:this.form_tipo,
        observaciones:this.form_observaciones,

      }).subscribe(data=>{

        this.form_name="";
        this.form_email="";
        this.form_tipo="def";
        this.form_tipo2="";
        this.form_observaciones="";

        this.alertType = "success";
        this.alert = "¡Se ha enviado con éxito!, si toda la información está correcta, te adjuntaremos por este correo electrónico, la factura para abonar directamente, si tenemos cualquier problema te contactaremos vía email. ¡Muchas Gracias!";
      });
    } else {
      this.alertType = "danger";
      this.alert = "¡La pregunta es incorrecta, revisa la parte de arriba de la web!";
    }
  }

}
