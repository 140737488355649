import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  loading:boolean = true;

  constructor() { }

  ngOnInit(): void {

    setTimeout(() => {
      this.loading = false;
    }, 2000);

  }

}
