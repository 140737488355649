import { EndpointService } from './../../services/endpoint.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [EndpointService]
})
export class HeaderComponent implements OnInit {

  menu;
  url;
  showMenu:boolean = false;

  constructor(private api: EndpointService) {}

  async ngOnInit() {

    let _url = this.api._url;
    this.url = _url;

    this.api.getData(_url+"/menus").subscribe(data=>{
      this.menu = data;
    });

  }

}
