<div class="carousel" *ngIf="imageToShow">
    <div (click)="close()" class="_bg"></div>

    <div (click)="carousel_action('left')" class="arrow arrow-left zi-2 text-white">
        <i class="fas fa-arrow-left"></i>
    </div>

    <div (click)="carousel_action('right')" class="arrow arrow-right zi-2 text-white">
        <i class="fas fa-arrow-right"></i>
    </div>

    <div  class="close zi-2 p-2 text-white">
        <i (click)="close()" class="fas fa-times"></i>
    </div>

    <img (click)="close()" src="{{imageToShow}}" class="{{class}}">

</div>

<div class="minh-100">
    <div class="row bg-primary">
        <div class="separator-h"></div>
        <div class="separator-h"></div>
        <div class="col text-center">
            <h1 class="text-white light">Galería</h1>
        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#9ae7ff" fill-opacity="1" d="M0,256L1440,64L1440,0L0,0Z"></path>
    </svg>
    <div class="row w-100 mt--200 mobile-nm">
        <div *ngIf="images">
            <div *ngFor="let i of images" class="col col-3 mobile-width text-center ib">
                <img (click)="setImage(i.url)" src="{{url+i.url}}" class="gal_img shrink">
            </div>
        </div>
    </div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#494949" fill-opacity="1" d="M0,256L1440,64L1440,320L0,320Z"></path>
</svg>